.footer {
  padding: 30px 20px;
  background-color: $headerBGColor;
  @include flexAlignItem(center, center);

  p {
    @include font(#ffffff, 400, 12px, 14px, normal, center);
    a {
      text-decoration: none;
      @include font(#ffffff, 400, 12px, 14px, normal, center);
    }

    a:hover {
      color: $buttonBGColor;
    }
  }
}