.discountBanner {
  height: 180px;
  background-color: #878786;

  .container {
    @include flexAlignItems(row, wrap, space-around, center);

    p:first-child {
      @include font(#fff, 800, 60px, 62px, normal, center);
    }

    img {
      width: 28px;
      margin: 0 10px;
    }

    p:last-child {
      width: 382px;
      @include font(#fff, 700, 26px, 30px, italic, center);

      span {
        font-size: 35px;
        line-height: 39px;
      }
    }
  }
}

@media screen and (max-width: 917px) {
  .discountBanner {
    height: 200px;
  }
}

@media screen and (max-width: 470px) {
  .discountBanner {

    .container {

      p:first-child {
        font-size: 45px;
        line-height: 47px;
      }

      img {
        width: 25px;
      }

      p:last-child {
        font-size: 26px;
        line-height: 28px;

        span {
          font-size: 33px;
          line-height: 35px;
        }
      }
    }
  }
}

@media screen and (max-width: 416px) {
  .discountBanner {

    .container {

      p:first-child {
        font-size: 35px;
        line-height: 37px;
      }

      img {
        width: 20px;
      }

      p:last-child {
        font-size: 20px;
        line-height: 22px;

        span {
          font-size: 24px;
          line-height: 26px;
        }
      }
    }
  }
}