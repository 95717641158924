.app {
  overflow-x: hidden;
}

.container {
  max-width: 1090px;
  width: 95%;
  height: 100%;
  margin: 0 auto;
  //background-color: fuchsia;
}