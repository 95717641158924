.emailBanner {
  padding: 50px 0 70px;
  background-color: #F0F0F0;

  .container {
    @include flexAlignItems(row, wrap-reverse, space-between, center);
  }

  &__leftSide,
  &__rightSide {
    width: 48%;
  }

  &__leftSide {
    @include flexAlignItems(column, nowrap, center, flex-start);

    p {
      @include font($headerBGColor, 700, 38px, 40px, italic, start);
      margin-bottom: 40px;
    }

    a {
      align-self: center;
    }
  }

  &__rightSide {
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 700px){
  .emailBanner__leftSide {
    margin-top: 50px;
    width: 100%;

    p {
      align-self: center;
      text-align: center;
    }
  }


  .emailBanner__rightSide {
    width: 80%;
    margin: 0 auto;
  }


}