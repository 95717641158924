.swiperArea {
  height: 450px;
  background-color: #F0F0F0;
  .container {
    display: flex;
    align-items: center;
  }
}

.swiper {
  width: 100%;
  height: 350px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px !important;
  height: 300px !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
}