.header {
  height: 92px;
  background-color: $headerBGColor;

  .container {
    @include flexAlignItem(center, center);
  }

  &__linkWrapper {
    width: 527px;
    @include flexAlignItems(row, nowrap, space-between, center);
  }

  &__imageLink {
    width: 44px;
    height: 44px;
  }
}

@media screen and (max-width: 450px){
  .header {
    &__linkWrapper {
      width: 90%;
    }
  }
}