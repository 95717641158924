.content {
  margin: 70px 0;

  &__firstRow, &__thirdRow {
    position: relative;

    &-leftSide {
      p {
        @include font($headerBGColor, 400, 22px, 31px, normal, start);
        margin-bottom: 45px;

        span {
          font-weight: 700;
        }
      }

      a {
        margin: 0 auto;
      }
    }

    &-rightSide {
      @include flexAlignItem(center, center);

      img {
        width: 100%;
      }
    }

    &-leftSide, &-rightSide {
      width: 47%;
      padding: 0 5px;
    }
  }

  &__firstRow:before {
    content: '';
    position: absolute;
    top: -85px;
    left: -305px;
    width: 531px;
    height: 265px;
    z-index: -1;
    background-image: url("../../assets/image/row1bg.png");
    background-position: bottom;
  }

  &__firstRow {
    @include flexAlignItems(row, wrap-reverse, center, flex-end);
  }

  &__secondRow {
    margin-top: 90px;
    @include flexAlignItems(row, wrap, center, center);

    &-leftSide {
      @include flexAlignItem(center, center);
    }

    &-rightSide {
      div {
        @include flexAlignItems(row, nowrap, flex-start, flex-start);

        img {
          margin-top: 5px;
        }

        p {
          @include font($headerBGColor, 400, 22px, 31px, normal, start);
          margin-left: 20px;
        }
      }

      div:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &-leftSide, &-rightSide {
      width: 47%;
      padding: 0 5px;
    }
  }


  &__thirdRow {
    @include flexAlignItems(row, wrap-reverse, center, center);
    margin-top: 90px;
  }

  &__thirdRow:after {
    content: '';
    position: absolute;
    top: -300px;
    right: -270px;
    width: 485px;
    height: 485px;
    z-index: -1;
    background-image: url("../../assets/image/row3bg.png");
    background-position: bottom;
  }
}

@media screen and (max-width: 778px) {
  .content__firstRow-leftSide,
  .content__firstRow-rightSide,
  .content__secondRow-leftSide,
  .content__secondRow-rightSide,
  .content__thirdRow-leftSide,
  .content__thirdRow-rightSide {
    width: 100%;
  }

  .content__firstRow-leftSide,
  .content__secondRow-rightSide,
  .content__thirdRow-leftSide {
    margin-top: 20px;
  }
}