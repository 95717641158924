.youtubeBanner {
  display: block;
  height: 90px;
  margin-bottom: 90px;
  text-decoration: none;

  .container {
    @include flexAlignItems(row, nowrap, space-around, center);
  }

  &__circle1,
  &__circle2,
  &__circle3,
  &__circle4 {
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }

  &__circle1,
  &__circle2,
  &__circle3 {
    margin-right: 20px;
  }

  &__circle1 {
    border: 20px solid #706F6F;
  }

  &__circle2 {
    border: 20px solid #878786;
  }

  &__circle3 {
    border: 20px solid #9D9D9C;
  }

  &__circle4 {
    border: 20px solid #ffffff;
    margin-left: -45px;
  }

  &__content {
    overflow: hidden;
    background-color: #DFDFDF;
    flex-grow: 1;
    height: 100%;
    @include flexAlignItems(row, nowrap, space-between, center);

    p {
      @include font($headerBGColor, 700, 31px, 31px, italic, start);
    }

    img:last-child {
      margin-right: 20px;
    }
  }
}

@media screen and (max-width: 1060px) {
  .youtubeBanner__circle1 {
    display: none;
  }
}

@media screen and (max-width: 940px) {
  .youtubeBanner__circle2 {
    display: none;
  }
}

@media screen and (max-width: 820px) {
  .youtubeBanner__circle3 {
    display: none;
  }
}

@media screen and (max-width: 710px) {
  .youtubeBanner__circle4 {
    display: none;
  }
  .youtubeBanner__content {
    padding-left: 10px;

    img:not(:last-child) {
      margin: 0 15px;
    }
  }
}

@media screen and (max-width: 430px) {
  .youtubeBanner {
    height: 135px;
  }
}