.customLink {
  text-decoration: none;
  width: fit-content;
  background-color: $buttonBGColor;
  padding: 10px 20px;
  border-radius: 50px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, .25);
  @include font(#fff, 700, 28px, 28px, normal, start);
  @include flexAlignItems(row, nowrap, center, center);

  img {
    margin-left: 18px;
  }
}